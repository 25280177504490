var generic = generic || {};
var site = site || {};
generic.rb.language = generic.rb("language");
(function($) {
  var formLocation = '/checkout/includes/forgot_pure_privilege_number_popup.tmpl';
  var formAction = '/checkout/includes/forgot_pure_privilege_number_popup.tmpl';
  var ppcontent = '/includes/content/pure_privilege_card_info_overlay.tmpl';
  var forgotPPNumber = '/checkout/includes/forgot_pp_number.tmpl';
  var overlayWidth = '530px';
  var overlayHeight = 'auto';
  var accountSignin = Drupal.settings && Drupal.settings.perlgem_account_checkout_signin_styles;

  $(document).on('pp::forgotnumber::card::init', function(e, data) {
    var $container = data.$container || document;
    var $forgotTriggersPc = $('.js-privilege-forgot-number-trigger-pc', $container);
    $forgotTriggersPc.once().on('click', function(e) {
      e.preventDefault();
      generic.overlay.launch({
        href: ppcontent,
        cssStyle: {
          width: $(this).data('overlayWidth') || overlayWidth,
          height: $(this).data('overlayHeight') || overlayHeight,
        },
        onComplete: overlayOnComplete,
        className: 'js-pure-privilege-forgot-overlay pure-privilege-forgot-overlay'
      });
    });
  });

  function overlayOnComplete() {
    var $container = $('.js-pure-privilege-forgot-overlay');
    if (!$container.length) {
      return;
    }
    initPPForgotLinks($container);
    initForgotForm($container);
    initPPRetryLinks($container);
    initPPNumberButton($container);
  }

  $(document).on('pp::forgotnumber::form::init', function() {
    var $forgotTriggers = $('.js-privilege-forgot-number-trigger');
    $forgotTriggers.once().on('click', function(event, string) {
      event.preventDefault();
      if (string === 'pp-number-notfound') {
        formLocation = forgotPPNumber;
      }
      generic.overlay.launch({
        href: formLocation,
        cssStyle: {
          width: $(this).data('overlayWidth') || overlayWidth,
          height: $(this).data('overlayHeight') || overlayHeight,
        },
        onComplete: overlayOnComplete,
        className: 'js-pure-privilege-forgot-overlay pure-privilege-forgot-overlay'
      });
    });
  });

  var bondApiStatus = function() {
    var $apiStatusNode = $('.js-site-header');
    var apiStatus = $apiStatusNode.attr('data-pp-status') || '';
    var apiMessage = $apiStatusNode.attr('data-pp-down-msg') || '';
    if (apiStatus === '0') {
      generic.overlay.launch({
        content: apiMessage,
        className: 'colorbox--system-message pure-privilege-forgot-overlay',
        cssStyle: {
          width: 'auto',
          height: 'auto'
        }
      });
    }
    return apiStatus;
  };

  var initForgotForm = function($container) {
    var $form = $('.pure-privilege-number-forgot-popup-page form', $container);

    $('.forgot_pp_submit', $form).once().on('click', function(event) {
      event.preventDefault();
      var ppStatus = bondApiStatus();
      if (ppStatus !== '0') {
        $.ajax({
          url: formAction,
          cache: false,
          data: $form.serialize(),
          method: 'POST',
        }).done(function(response) {
          var $errors = $(response).find('#form--errors--pure_privilege_number_send');
          var $responsePage = $(response).find('.js-pp-response-page');
          if ($errors.children('.s').length || $errors.children('.server').length) {
            $('.error_messages', $container).replaceWith($errors);
            initPPForgotLinks($container);
            initForgotForm($container);
            if (accountSignin) {
              $('#form--errors--pure_privilege_number_send [id^=no_account]').text(generic.rb.language.get('forgot_pp_retry_msg_mobile'));
            }
          } else {
            $('.pure-privilege-number-forgot-popup-page', $container).replaceWith($responsePage);
            initOverlayClose($container);
            if (!accountSignin) {
              initPPRetryLinks($container);
            }
            initPPNumberButton($container);
          }
        });
      }
    });
  };

  var initOverlayClose = function($container) {
    $('.js-pp-success-btn', $container).once().click(function() {
      generic.overlay.hide();
    });
  };

  var initPPRetryLinks = function($container) {
    $('.js-pp-retry-btn', $container).once().click(function() {
      generic.overlay.launch({
        href: formLocation,
        cssStyle: {
          width: $(this).data('overlayWidth') || overlayWidth,
          height: $(this).data('overlayHeight') || overlayHeight,
        },
        onComplete: overlayOnComplete,
        className: 'js-pure-privilege-forgot-overlay pure-privilege-forgot-overlay'
      });
    });
  };

  var initPPNumberButton = function($container) {
    $('.js-pp-number-button', $container).once().click(function() {
      generic.overlay.launch({
        href: forgotPPNumber,
        cssStyle: {
          width: $(this).data('overlayWidth') || overlayWidth,
          height: $(this).data('overlayHeight') || overlayHeight,
        },
        onComplete: overlayOnComplete,
        className: 'js-pure-privilege-forgot-overlay pure-privilege-forgot-overlay'
      });
    });
  };

  var initPPForgotLinks = function($container) {
    $(document).trigger('pp::forgotnumber::form::init', {
      '$container': $container
    });
  };

  var initPPForgot = function($container) {
    $(document).trigger('pp::forgotnumber::card::init', {
      '$container': $container
    });
  };

  site.pp_number_forgot = {
    init: function(context) {
      this.cardInit(context);
      this.formInit(context);
    },
    cardInit: function(context) {
      initPPForgot(context);
    },
    formInit: function(context) {
      initPPForgotLinks(context);
    },

  };
})(jQuery);
